import React from 'react';

import PropTypes from 'prop-types'
import Link from 'gatsby-link';

import {
  INTERNAL_LINK,
  EXTERNAL_LINK
} from '../../utils/constants';

const Button = ({ linkType, link, text, color }) => {
  if (linkType === INTERNAL_LINK) {
    return (
      <div>
        <Link to={ link } className={`btn btn--transparent-${color}`}>{ text }</Link>
      </div>
    )
  } else if (linkType === EXTERNAL_LINK) {
    return (
      <div>
        <a href={ link } className={`btn btn--transparent-${color}`}>{ text }</a>
      </div>
    )
  }
};

Button.propTypes = {
  linkType: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired
};

export default Button;
