import React from 'react';

import Link from 'gatsby-link';
import PropTypes from 'prop-types'

import {
  INTERNAL_LINK,
  EXTERNAL_LINK
} from '../utils/constants';

const FooterLink = ({ linkType, link, title }) => {
  if (linkType === INTERNAL_LINK) {
    return (
      <div className="col-12 center-sm right">
        <Link to={ link } className="white footer-link">{ title }</Link>);
      </div>
    )
  } else if (linkType === EXTERNAL_LINK) {
    return (
      <div className="col-12 center-sm right">
        <a href={ link } className="white footer-link">{ title }</a>);
      </div>
    )
  }
};

FooterLink.propTypes = {
  linkType: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired
};

export default FooterLink;
