import React from 'react';

import Footer from './Footer';

import Helmet from 'react-helmet';
import PropTypes from 'prop-types';

import appleTouchIcon from '../assets/favicon/apple-touch-icon.png';
import favicon16 from '../assets/favicon/favicon-16x16.png';
import favicon32 from '../assets/favicon/favicon-32x32.png';
import safariPinnedTab from '../assets/favicon/safari-pinned-tab.svg';
import logotype from '../assets/graphics/flatwave-og-logotype.png';
import '../styles/index.css';

const Layout = ({ children }) => (
  <div>
    <Helmet
      title="flatwave.se"
      link={[
        { rel: 'apple-touch-icon', sizes: '180x180', href: appleTouchIcon },
        { rel: 'icon', sizes: '180x180', href: appleTouchIcon },
        { rel: 'icon', type: 'image/png', sizes: '16x16', href: favicon16 },
        { rel: 'icon', type: 'image/png', sizes: '32x32', href: favicon32 },
        { rel: 'mask-icon', href: safariPinnedTab, color: '#101320' }
      ]}
      meta={[
        { name: 'apple-mobile-web-app-title', content: 'flatwave' },
        { name: 'application-name', content: 'flatwave' },
        { name: 'msapplication-TileColor', content: '#ffffff' },
        { name: 'theme-color', content: '#ffffff' },
        { property: 'og:type', content: 'website' },
        { property: 'og:title', content: 'Flatwave' },
        { property: 'og:description', content: 'Konsultbolaget som sätter användaren i centrum.' },
        { property: 'og:url', content: 'https://www.flatwave.se/' },
        { property: 'og:image', content: `https://www.flatwave.se${logotype}` }, //fugly hack to get an absolute url in production
        { property: 'og:image:height', content: '630' },
        { property: 'og:image:width', content: '1200' }
      ]}
    />
    { children }
    <Footer />
  </div>
);

Layout.propTypes = {
  children: PropTypes.object.isRequired
};

export default Layout;
