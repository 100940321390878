import React from 'react';
import { graphql, StaticQuery } from 'gatsby';

import FooterLink from './FooterLink';

import logotype from '../assets/graphics/flatwave-logotype.svg';

const Footer = () => (
  <div className="footer">
    <div className="footer-top"></div>
    <div className="footer-hero">
      <div className="container">
        <div className="row">
          <div className="col-6 center-sm">
            <img className="logotype" src={ logotype } alt={ "Flatwave logo" } />
          </div>
          <div className="col-6 center-sm">
            <div className="row">
              <StaticQuery
                query={graphql`
                  query MainMenuQuery {
                    contentfulMenuMenuDataJsonNode {
                      menu {
                        link
                        title
                        linkType
                      }
                    }
                  }
                `}
                render={data => data.contentfulMenuMenuDataJsonNode.menu.map(item => (
                  <FooterLink
                    key={item.title}
                    linkType={item.linkType}
                    title={item.title}
                    link={ item.link }
                  />
                ))}
              />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <div className="row">
              <div className="col-7 hidden-sm"></div>
              <div className="col-12-sm col-5 center-sm">
                <div className="footer__icon-credits">
                  Icons made by <a href="https://www.flaticon.com/authors/smashicons" title="smashicons">smashicons</a> from <a href="https://www.flaticon.com/" title="Flaticon">www.flaticon.com</a>, licensed under <a href="http://creativecommons.org/licenses/by/3.0/" title="Creative Commons BY 3.0" rel="noopener noreferrer">CC&nbsp;3.0&nbsp;BY</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default Footer;
