import React from 'react';

import Link from 'gatsby-link';
import PropTypes from 'prop-types';

import delimiter1 from '../assets/graphics/flatwave-delimiter-1.png';

const Header = ({ title, subtitle }) => (
  <div className="hero">
    <div className="waves"></div>
    <div className="container">
      <Link className="logomark" to="/"></Link>
      <div className="row">
        <div className="col-1 hidden-sm"></div>
        <div className="col-12-sm col-10 center">
          <h1 className="white">{ title }</h1>
          { subtitle && <p className="white intro">{ subtitle }</p> }
        </div>
        <div className="col-1 hidden-sm"></div>
      </div>
    </div>
    <img className="delimiter-bottom" src={ delimiter1 } alt={ "Flatwave delimiter" } />
  </div>
);

Header.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string
};

export default Header;
