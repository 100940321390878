import React from 'react';

import PropTypes from 'prop-types';

const Badge = ({ icon, title, quote }) => (
  <div className="col-4 center badge">
    <img className="badge-icon" src={ icon } alt={ title + " badge"}/>
    <div className="badge-title">
      <strong>{ title }</strong>
    </div>
    <div className="badge-quote quote">{ quote }</div>
  </div>
);

Badge.propTypes = {
  icon: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  quote: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object
  ])
};

export default Badge;
